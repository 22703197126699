import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import { getAudurFutureAccountInterestText, getMarketFutureAccountInterestText } from '@kvika/audur-strings';
import { getScaledCalcValue, getCalcPosition } from '@kvika/audur-utils';
import { toIskCurrencyString } from '@kvika/string-utils';
import { AudurMarketInterestRatesSavingsMarketInterestRates, Maybe } from '@kvika/audur-prismic-types';
import { ProductSchema } from '@kvika/audur-api-types';
import styles from './CalculatorStyles.module.scss';
import ProjectedEarnings from './ProjectedEarnings';
import SliderRow from './SliderRow';
import { GridContainer, GridItem } from '../grid-container';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { stringToInt } from '../../utils/Utils';

type Props = {
  initialBalance?: number;
  interestRate: number;
  companyMarketInterestRates: AudurMarketInterestRatesSavingsMarketInterestRates;
};

export declare type ProjectedEarnings = {
  balance: number;
  interest: number;
};

const projectedCompanyAccountEarnings = (
  interestRate: number,
  originalBalance: number,
  years: number
): ProjectedEarnings => {
  const interestPercentage = interestRate / 100.0;

  if (originalBalance <= 0) {
    return { balance: 0, interest: 0 };
  }

  let principal = originalBalance;
  let accruedInterest = 0;

  for (let i = 0; i < years; i += 1) {
    const yearlyPrincipalInterest = principal * interestPercentage;
    principal += yearlyPrincipalInterest;
    accruedInterest += yearlyPrincipalInterest;
  }

  return {
    balance: Math.floor(principal),
    interest: Math.floor(accruedInterest),
  };
};

const getAvgMarketInterestRate = (
  marketInterestRates: AudurMarketInterestRatesSavingsMarketInterestRates,
  originalBalance?: number
) => {
  let interestRate = 0 as Maybe<number>;
  if (!originalBalance || (originalBalance && originalBalance < 1000000))
    interestRate = marketInterestRates.lowDepositRate;
  else if (originalBalance >= 1000000 && originalBalance < 5000000) interestRate = marketInterestRates.midDepositRate;
  else if (originalBalance >= 5000000 && originalBalance < 20000000)
    interestRate = marketInterestRates.midToHighDepositRate;
  else if (originalBalance >= 20000000) interestRate = marketInterestRates.highDepositRate;
  return interestRate || 0;
};

const CompanyCalculator: FunctionComponent<Props> = ({
  initialBalance = 0,
  interestRate,
  companyMarketInterestRates,
}) => {
  const { calculatorStrings } = TranslatedStrings.is;
  const [originalBalance, setOriginalBalance] = useState<number | undefined>(initialBalance);
  const [originalBalancePos, setOriginalBalancePos] = useState<number | undefined>(0);
  const [years, setYears] = useState<number | undefined>(1);
  const [projectedBalance, setProjectedBalance] = useState<string>('');
  const [projectedInterest, setProjectedInterest] = useState<string>('');
  const [avgMarketInterest, setAvgMarketInterest] = useState<string>('');
  const [avgMarketInterestRate, setAvgMarketInterestRate] = React.useState<number>(0);

  const handleChangeBalanceSlider = (event: ChangeEvent<HTMLInputElement>) => {
    const position = stringToInt(event.target.value);
    const balance = getScaledCalcValue(position);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };

  const handleChangeBalanceInput = (balance: number | undefined) => {
    const position = getCalcPosition(balance);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };

  const handleChangeYears = (event: ChangeEvent<HTMLInputElement>) => {
    setYears(stringToInt(event.target.value));
  };

  useEffect(() => {
    const marketInterest = getAvgMarketInterestRate(companyMarketInterestRates, originalBalance);
    const projectedEarnings = projectedCompanyAccountEarnings(interestRate, originalBalance ?? 0, years ?? 0);

    const projectedAvgMarketEarnings = projectedCompanyAccountEarnings(
      marketInterest,
      originalBalance ?? 0,
      years ?? 0
    );

    setProjectedBalance(toIskCurrencyString(projectedEarnings.balance, true));
    setProjectedInterest(toIskCurrencyString(projectedEarnings.interest, true));
    setAvgMarketInterest(toIskCurrencyString(projectedAvgMarketEarnings.interest, true));
    setAvgMarketInterestRate(marketInterest);
  }, [originalBalance, years, interestRate, companyMarketInterestRates]);

  const clearState = () => {
    setOriginalBalance(0);
    setOriginalBalancePos(getCalcPosition(0));
    setYears(1);
  };

  return (
    <GridContainer>
      <GridItem className={styles.gridColumnsLeft} style={{ justifyContent: 'flex-start' }}>
        <SliderRow
          title={calculatorStrings.originalBalance}
          value={originalBalancePos}
          valueToDisplay={originalBalance}
          valueSuffix="kr."
          min={0}
          max={200}
          step={1}
          onChangeSlider={(event) => handleChangeBalanceSlider(event)}
          onChangeInput={handleChangeBalanceInput}
        />
        <SliderRow
          title={calculatorStrings.time}
          value={years}
          valueSuffix="ár"
          isCurrency={false}
          min={1} // Min 1 years
          max={12} // Max 12 years
          onChangeSlider={(event) => handleChangeYears(event)}
          onChangeInput={setYears}
        />
      </GridItem>
      <GridItem className={styles.gridColumnsRight} style={{ justifyContent: 'flex-start' }}>
        {interestRate ? (
          <ProjectedEarnings
            selectedProduct={{ interestRate } as ProductSchema}
            projectedBalance={projectedBalance}
            projectedInterest={projectedInterest}
            averageMarketInterest={avgMarketInterest}
            averageMarketInterestRate={avgMarketInterestRate}
            onClearButtonClick={clearState}
            audurInterestTooltipText={getAudurFutureAccountInterestText(interestRate)}
            marketInterestTooltipText={getMarketFutureAccountInterestText(avgMarketInterestRate)}
          />
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default CompanyCalculator;
