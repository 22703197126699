import React, { CSSProperties, FunctionComponent } from 'react';

import { motion } from 'framer-motion';

import styles from './CalculatorTabs.module.scss';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { CalculatorTab } from '../../types/Types';
import { SegmentTrackingId, trackEvent } from '../../utils/Analytics';

type Props = {
  onSwitchTabs(selectedTab: CalculatorTab): void;
  selectedTab: CalculatorTab;
  style?: CSSProperties;
};

const CalculatorTabs: FunctionComponent<Props> = ({ onSwitchTabs, selectedTab, style }) => {
  const { calculatorStrings } = TranslatedStrings.is;
  const { SavingsCalculator, TermCalculator, FutureCalculator, CompanyCalculator } = CalculatorTab;
  const switchTabs = (selectedTab: CalculatorTab) => {
    trackEvent({
      event: SegmentTrackingId.CalculatorOnTabSwitch,
      properties: { selectedTab },
    });
    onSwitchTabs(selectedTab);
  };
  const isSavings = selectedTab === SavingsCalculator;
  const isTerm = selectedTab === TermCalculator;
  const isFuture = selectedTab === FutureCalculator;
  const isCompany = selectedTab === CompanyCalculator;
  return (
    <motion.div className={styles.tabs} style={style}>
      <motion.button
        className={`${styles.button} ${isSavings ? styles.selected : ''}`}
        onClick={() => switchTabs(SavingsCalculator)}
      >
        {calculatorStrings.savingsTabTitle}
      </motion.button>
      <motion.button
        className={`${styles.button} ${isTerm ? styles.selected : ''}`}
        onClick={() => switchTabs(TermCalculator)}
      >
        {calculatorStrings.termTabTitle}
      </motion.button>
      <motion.button
        className={`${styles.button} ${isFuture ? styles.selected : ''}`}
        onClick={() => switchTabs(FutureCalculator)}
      >
        {calculatorStrings.futureTabTitle}
      </motion.button>
      <motion.button
        className={`${styles.button} ${isCompany ? styles.selected : ''}`}
        onClick={() => switchTabs(CompanyCalculator)}
      >
        {calculatorStrings.companyTabTitle}
      </motion.button>
    </motion.div>
  );
};

export default CalculatorTabs;
